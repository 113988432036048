import React from "react";
import LineaBase from "../components/forms/LineaBase";

function PreguntasLinea() {
  return (
    <>
      <LineaBase />
    </>
  );
};

export default PreguntasLinea;